import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <main className="relative flex items-center justify-center h-full bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 text-white ">
      <div className="xl:pb-20 flex flex-col">
        <main className="flex-grow flex flex-col text-white justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-blue uppercase tracking-wide">
                Oops! It seems you've lost your way.
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gris dark:text-white tracking-tight sm:text-5xl">
                The mystical land of 404 has swallowed this page.
              </h1>
              <p className="mt-2 text-base text-gray-500 dark:text-gray-100">
                Don't worry, our team of unicorns and kittens is on a quest to find it!
              </p>
              <div className="mt-6">
                <img
                  src="https://media.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif"
                  alt="Funny Cat Gif"
                  className="mx-auto mb-4 rounded-md shadow-lg"
                  width = "100px"
                  height = "100px"
                />
                <Link to="/" className="text-base font-medium text-blue">
                  Lead the way back home (before the unicorns get distracted) →
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </main>
  );
};

export default NotFound;
