import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import Home from "../page/Home";
import Room from "../page/Room";
import NotFound from "../page/NotFound";
import SetInterview from "../page/SetInterview";
import { useAuth } from "../context/AuthContext";
import Interviews from "../page/Interviews";
import SendToRoom from "./SendToRoom";
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user, login, logout } = useAuth();

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Main Content */}
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* Header */}
        <Header />
        {/* Content */}
        <div className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Conditionally render SetInterview based on login status */}
            {user ? (
              <>
                <Route path="/setInterview" element={<SetInterview />} />
                <Route path="/joinInterview" element={<Interviews />} />
              </>
            ) : (
              <>
                <Route
                  path="/setInterview"
                  element={<LoginAlert login={login} />}
                />
                <Route
                  path="/joinInterview"
                  element={<LoginAlert login={login} />}
                />
              </>
            )}
            <Route path="/room/:roomID" element={<SendToRoom/>} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export const LoginAlert = ({ login }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <div>
            <button
              onClick={login}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Signup with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
