import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import InterviewRoom from "../page/InterViewerRoom";
import Room from "../page/Room";
import { LoginAlert } from "./App";

const SendToRoom = () => {
  const { user, login } = useAuth();
  const { roomID } = useParams();
  const [roomDetails, setRoomDetails] = useState({});
  const [error, setError] = useState("");
  const [isMounted, setIsMounted] = useState(true);

  async function fetchRoomDetails() {
    console.log(user.email);
    console.log(roomID);
    try {
      const response = await axios.get(
        ` https://possible-lionfish-tight.ngrok-free.app/api/appointments/roomId/`,
        { params: { roomID: roomID } }
      )
      if (isMounted) {
        setRoomDetails(response.data);
        console.log(response.data);
      }
    } catch (error) {
      if (isMounted) {
        setError(error.message);
      }
    }
  }

  useEffect(() => {
    fetchRoomDetails();
    return () => {
      setIsMounted(false);
    };
  }, [roomID]);

  if (!user) {
    return <LoginAlert login={login} />;
  } else if (roomDetails.candidateEmail === user.email) {
    // const startDate = new Date(roomDetails.startDate);
    // const endDate = new Date(roomDetails.endDate);
    // const now = new Date();

    // if (now < startDate) {
    //   // Interview has not started yet
    //   const timeUntilStart = startDate - now;
    //   const minutesUntilStart = Math.floor(timeUntilStart / 60000);
    //   const hours = Math.floor(minutesUntilStart / 60);
    //   const minutes = minutesUntilStart % 60;

    //   return (
    //     <div className="text-center mt-8">
    //       <p className="text-xl">Interview has not started yet.</p>
    //       <p className="text-lg mt-4">
    //         Please come back in {hours} hours and {minutes} minutes.
    //       </p>
    //     </div>
    //   );
    // } else if (now > endDate) {
    //   // Interview has passed
    //   return (
    //     <div className="text-center mt-8">
    //       <p className="text-xl">Interview has already passed.</p>
    //       <p className="text-lg mt-4">
    //         If you have given click on the link to get the detailed report by
    //         mail.
    //       </p>
    //     </div>
    //   );
    // } else {
      // Interview is ongoing
      return <InterviewRoom roomDetails={roomDetails} />;
    // }
  } else if (roomDetails.interviewEmail === user.email) {
    return <Room roomDetails={roomDetails} />;
  } else {
    return (
      <div>
        <h1>
          You are not authorized to enter this Interview if you think this is a
          mistake please contact the admin@InterviewBlitz.in
        </h1>
      </div>
    );
  }
};

export default SendToRoom;
