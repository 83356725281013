import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { AiOutlineLogout as LogOutIcon } from "react-icons/ai";

const Header = () => {
  const { user, login, logout } = useAuth();

  return (
    <div className="h-16 px-4 bg-gradient-to-r from-indigo-600 via-purple-400 to-pink-500 text-white flex items-center">
      <Link to="/" className="flex items-center mr-4">
        <img className="h-10 w-10" src="/images/logo.svg" alt="logo" />
        <span className="ml-2 text-lg font-bold">Interview Blitz</span>
      </Link>
      <div className="flex-grow"></div>
      <div>

        {user ? (
          
          <div className="relative group h-10 w-10 rounded-full overflow-hidden">
            <img
              className="h-full w-full rounded-full object-cover"
              src={user?.photoURL}
              alt={user?.displayName}
            />
            <button
              className="absolute flex items-center justify-center top-0 left-0 h-full w-full bg-black/70 opacity-0 transition-all group-hover:opacity-100"
              onClick={logout}
              title="Logout"
            >
              <LogOutIcon size={20} />
            </button>
          </div>
        ) : (
          <button
            className="bg-yellow py-2 px-6 text-white font-semibold text-sm rounded-full border-2 border-transparent hover:border-yellow hover:bg-transparent hover:text-yellow duration-200"
            onClick={login}
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
