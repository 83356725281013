import React from "react";

const HomeCard = ({ icon, title, desc, bgColor, route }) => {
  return (
    <div
      className={`p-4 md:p-6 rounded-md h-full w-full bg-gradient-to-r from-pink-600 via-purple-500 to-indigo-500 shadow-md bg-slate-400/40 hover:bg-slate-400/20 from-opacity-80 via-opacity-90 to-opacity-100 hover:from-opacity-100 hover:via-opacity-90 hover:to-opacity-80 transform transition-transform duration-300 hover:scale-105 cursor-pointer`}
    >
      <div className="flex flex-col h-full items-center justify-between">
        <div
          className={`text-white text-4xl bg-pink-400 md:h-16 md:w-16 flex items-center justify-center bg-slate-400/40 hover:bg-slate-400/20 rounded-full mb-4`}
        >
          {icon}
        </div>
        <div className="flex-shrink-0 text-center">
          <p className="text-white text-lg font-bold mb-1">{title}</p>
          <p className="text-gray-300 font-serif text-sm">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;

