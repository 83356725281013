import React, { useEffect, useState } from "react";
import HomeCard from "../components/HomeCard";
import { v4 as uuid } from "uuid";
import { MdVideoCall as NewCallIcon } from "react-icons/md";
import { MdAddBox as JoinCallIcon } from "react-icons/md";
import { BsCalendarDate as CalendarIcon } from "react-icons/bs";
import { MdScreenShare as ScreenShareIcon } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { Link } from "react-router-dom";

const roomId = uuid();

const Home = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [date, setDate] = useState(new Date());

  function refreshClock() {
    setDate(new Date());
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  return (
    <div className="bg-gradient-to-r from-indigo-600 via-purple-400 to-pink-500 min-h-screen text-white p-6">
      <div className="container mx-auto flex flex-col justify-center items-center">
        <h1 className="text-4xl font-bold mb-4">Welcome to Interview Blitz </h1>
        <p className="text-lg font-serif mb-10">Test, Interview, and Hire in a fast-forward way!</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <Link to={'/setInterview'}>
            <HomeCard
              title="Schedule Interview"
              desc="Create a new Interview Settings" 
              icon={<NewCallIcon />}
              iconBgColor="bg-pink-500"
            />
          </Link>
          <Link to={'/joinInterview'}>
            <HomeCard
              title="Join a Meeting"
              desc="Join via invitation link"
              icon={<JoinCallIcon />}
              // iconBgColor="bg-blue-500"
            />
          </Link>
          <Link to={'/result'}>
            <HomeCard
              title="Results"
              desc="View Results of the Interview"
              icon={<BiSolidReport />}
              // iconBgColor="bg-purple-500"
            />
          </Link>

        </div>
        <div className="mt-8 text-center">
          <p className="text-lg">
            Current Time:{" "}
            <span className="text-3xl font-semibold">
              {`${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
                date.getMinutes() < 10
                  ? `0${date.getMinutes()}`
                  : date.getMinutes()
              }`}
            </span>
          </p>
          <p className="text-md mt-2">
            {`${days[date.getDay()]}, ${date.getDate()} ${
              months[date.getMonth()]
            } ${date.getFullYear(



              

            )}`}
          </p>
        </div>
        <div className="mt-8 text-center">
          <p className="text-lg">
            Made with ❤️
            
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
