import React, { useState, useEffect } from 'react';
import rolesData from '../data/roles.json';
import axios from 'axios';
const Checklist = ({ roomDetails }) => {
  const { TechRole, Role } = roomDetails;
  const role = rolesData.tech_roles.find(role => role.role === TechRole);
  const [definedRoles, setDefinedRoles] = useState([]);
  const [marks, setMarks] = useState({});

  useEffect(() => {
    if (role) {
      const matchedDefinedRoles = role.defined_roles.filter(
        definedRole => Role.includes(definedRole.role_name)
      );
      setDefinedRoles(matchedDefinedRoles);

      const initialMarks = Object.fromEntries(
        matchedDefinedRoles.flatMap(definedRole =>
          definedRole.skills.flatMap(skill => [
            [skill.skill_name, 0],
            ...skill.topics_covered.map(topic => [`${skill.skill_name}-${topic}`, 0])
          ])
        )
      );
      setMarks(initialMarks);
    }
  }, [Role, TechRole, role]);

  const handleMarkChange = (name, value) => {
    setMarks(prevMarks => ({
      ...prevMarks,
      [name]: prevMarks[name] === value ? 0 : value,
    }));
  };

  const handleSubmit = async () => {
    // Check if marks have already been submitted
    if (Object.keys(marks).length === 0) {
      alert("Marks have already been submitted. You can only submit once.");
      return;
    }
  
    // Ask for confirmation before submitting
    const confirmed = window.confirm("Are you sure you want to submit?  You can only submit once. ");
  
    if (confirmed) {
      const formattedMarks = {};
  
      definedRoles.forEach((definedRole) => {
        definedRole.skills.forEach((skill) => {
          const skillMarks = marks[skill.skill_name];
          const topicMarks = {};
  
          skill.topics_covered.forEach((topic) => {
            topicMarks[topic] = marks[`${skill.skill_name}-${topic}`];
          });
  
          formattedMarks[skill.skill_name] = {
            marks: skillMarks,
            topics_covered: topicMarks, // Corrected property name
          };
        });
      });
  
      console.log("Marks submitted:", formattedMarks);
      const marksData = {
        roomId: roomDetails.roomId,
        Role: roomDetails.Role,
        TechRole: roomDetails.TechRole,
        candidateEmail: roomDetails.candidateEmail,
        interviewEmail: roomDetails.interviewEmail,
        interviewInfo: roomDetails.interviewInfo,
        user: roomDetails.user,
        MarksResult: formattedMarks,
      };
      console.log("Marks data:", marksData);
  
      try {
        const response = await axios.post(
          "https://possible-lionfish-tight.ngrok-free.app/api/results/",
          marksData
        );
        console.log(response.data);
        alert("Marks submitted successfully! You can end the interview.");
        // Reset marks
        setMarks({});
        // Close the modal
      } catch (error) {
        console.log(error);
        alert("An error occurred while submitting marks. Please try again.");
      }
    }
  };
  

  return (
    <div 
    style={{maxHeight: "calc(100vh - 100px)"}}
    
    className=" h-full overflow-y-auto p-4 shadow-lg rounded-lg">
      <h2 className="text-lg font-bold mb-4 text-gray-800">Interview Checklist</h2>
      <div>
        {definedRoles.map(definedRole => (
          <div key={definedRole.role_name} className="mb-4">
            <h3 className="text-md font-semibold mb-2 text-gray-800">{definedRole.role_name}</h3>
            <ul>
              {definedRole.skills.map(skill => (
                <li key={skill.skill_name} className="mb-2">
                  <div className="flex justify-between items-center text-gray-800">
                    <span className="text-sm">{skill.skill_name}</span>
                    <div className="flex items-center space-x-2">
                      {[1, 2, 3, 4, 5].map(value => (
                        <label key={value} className="inline-flex items-center">
                          <input
                            type="radio"
                            className="form-radio h-4 w-4 text-blue-500"
                            name={`${skill.skill_name}-rating`}
                            value={value}
                            checked={marks[skill.skill_name] === value}
                            onChange={() => handleMarkChange(skill.skill_name, value)}
                          />
                          <span className="ml-1">{value}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  {skill.topics_covered.length > 0 && (
                    <div className="ml-4 mt-2">
                      <span className="text-sm font-semibold">Topics Covered:</span>
                      <ul className="list-disc ml-4">
                        {skill.topics_covered.map(topic => (
                          <li key={topic} className="text-xs text-gray-500 flex items-center">
                            <span>{topic}</span>
                            {[1, 2, 3, 4, 5].map(value => (
                              <label key={value} className="inline-flex items-center ml-2">
                                <input
                                  type="radio"
                                  className="form-radio h-4 w-4 text-blue-500"
                                  name={`${skill.skill_name}-${topic}-rating`}
                                  value={value}
                                  checked={marks[`${skill.skill_name}-${topic}`] === value}
                                  onChange={() => handleMarkChange(`${skill.skill_name}-${topic}`, value)}
                                />
                                <span className="ml-1">{value}</span>
                              </label>
                            ))}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default Checklist;
