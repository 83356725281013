import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  MonthView,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AllDayPanel,
  AppointmentTooltip,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from 'axios'; 
import { useAuth } from "../context/AuthContext"
import LinearProgress from '@mui/material/LinearProgress';
import {customUUID} from "../utils/roomID"



const StyledDiv = styled('div')({
  toolbarRoot: 'interview-toolbarRoot',
});


const ToolbarWithLoading = (
  ({ children, ...restProps }) => (
    <StyledDiv className="toolbarRoot">
      <Toolbar.Root {...restProps}>
        {children}
      </Toolbar.Root>
      <LinearProgress className="interview-progress" />
    </StyledDiv>
  )
);



const Interviews = () => {
  const date = new Date();  
  const { user, login, logout } = useAuth();


  const AppointmentTooltipCustom = (props) => {
    const { appointmentData ,  } = props;
    let backgroundColor = 'red';
    if (user.email === appointmentData.interviewEmail) {
      backgroundColor = '#FFC107';
    } else if (user.email === appointmentData.candidateEmail) {
      backgroundColor = 'green';
    }
    return (
      <div 
        className="bg-white border border-gray-200 shadow-lg rounded-lg p-4"
        style={{ backgroundColor }}
      >
        <h2 className="text-xl font-semibold mb-2">{
          user.email === appointmentData.interviewEmail ? 'Take Interview on Time' : 
          user.email === appointmentData.candidateEmail ? 'Give Your Interview on Time' : 'Interview Detail'
        }
        </h2>
        <p><strong>Job Title:</strong> {appointmentData.title}</p>
        <p><strong>Information:</strong> {appointmentData.interviewInfo}</p>
        <p><strong>Start Date:</strong> {new Date(appointmentData.startDate).toLocaleString()}</p>
        <p><strong>End Date:</strong> {new Date(appointmentData.endDate).toLocaleString()}</p>
        <Link to={`/room/${appointmentData.roomId}`} >
          {
            user.email === appointmentData.interviewEmail ? 'Take Interview' : 
            user.email === appointmentData.candidateEmail ? 'Attend Interview' : 'Interview Details'
          }
        </Link>
      </div>
    );
  };




 
  const fetchAppointments = async (userEmail) => {
    try {
        const response = await axios.get(' https://possible-lionfish-tight.ngrok-free.app/api/appointments/scheduled', {
            params: {
                userEmail: userEmail
            }
        }); 
        return response.data;
    } catch (error) {
        console.error('Error fetching appointments:', error);
        throw error; 
    }
  };

  useEffect(() => {
    setLoading(true);
    if (user) {
      fetchAppointments(user.email)
        .then((appointments) => {
          console.log('Appointments:', appointments);
          setData(appointments);
        })
        .catch((error) => {
          console.error('Error fetching appointments:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);


  const [currentDate, setCurrentDate] = useState(date);
  const [currentViewName, setCurrentViewName] = useState('Work Week');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentViewNameChange = (currentViewName) => {
    setCurrentViewName(currentViewName);
  };

  const currentDateChange = (currentDate) => {
    setCurrentDate(currentDate);
  };

  return (
    <Paper>
      <Scheduler
        data={data}
        height={660}
      >
        <ViewState
          currentDate={currentDate}
          currentViewName={currentViewName}
          onCurrentViewNameChange={currentViewNameChange}
          onCurrentDateChange={currentDateChange}
        />
        <DayView
          startDayHour={9}
          endDayHour={19}
          intervalCount={2}
        />
        <WeekView
          startDayHour={9}
          endDayHour={17}
          excludedDays={[0, 6]}
          name="Work Week"
        />
        <MonthView
          name="Month"
          startDate={currentDate}
          endDate={currentDate}
          today={currentDate}
          otherMonth={true}
        />
        <AllDayPanel />
        <Appointments
          appointmentContentComponent={({ data, ...restProps }) => {
            return (
              <Appointments.AppointmentContent {...restProps} data={data}
                style={{
                  backgroundColor: user.email === data.interviewEmail ? '#FFC107' : 
                                   user.email === data.candidateEmail ? 'green' : 'red'
                }}
              >
                <div 
                  className="flex flex-col justify-center items-center w-full h-full text-sm text-white"
                >
                  <div className="font-semibold text-lg text-center w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {
                      user.email === data.interviewEmail ? ' (Interviewer)' : 
                      user.email === data.candidateEmail ? ' (Candidate)' : ''
                    }
                  </div>
                  <div className="mt-2">
                    <Link to={`/room/${data.roomId}`} className="text-blue-500 underline">
                      {
                        user.email === data.interviewEmail ? 'Take Interview' : 
                        user.email === data.candidateEmail ? 'Attend Interview' : 'Interview Details'
                      }
                    </Link>
                  </div>
                </div>
              </Appointments.AppointmentContent>
            );
          }}
        />

        <Toolbar {...loading ? { rootComponent: ToolbarWithLoading } : null} />
        <DateNavigator />
        <ViewSwitcher />
        <AppointmentTooltip contentComponent={AppointmentTooltipCustom}  user={user}/>
      </Scheduler>
    </Paper>
  );
};

export default Interviews;   
